
import Vue from 'vue'
import VueRouter from 'vue-router';
import store from './store/explosion'; // Importa tu almacenamiento Vuex (Vuex Store)


Vue.use(VueRouter);




const routes = [{
    path: "/login",
    name: "login",

    component: () =>
        import ("./views/account/login"),
},
{
    path: "/home",
    name: "home",
    meta: { authRequired: true },
    component: () =>
        import ("./views/home"),
},
{
    path: "/social-reasons-index",
    name: "social-reasons-index",
    meta: { authRequired: true },
    component: () =>
        import ("./views/social_reasons/index"),
},
{
    path: "/social_reasons/create",
    name: "social-reasons-create",
    meta: { authRequired: true },
    component: () =>
        import ("./views/social_reasons/create"),
},
{
    path: "/social-reasons-edit/:id",
    name: "edit-social-reason",
    meta: { authRequired: true },
    component: () =>
        import ("./views/social_reasons/edit"),
    props: true
},
{
    path: "/bussiness-unit-index/:social_reason_id/:name",
    name: "index-bussiness_unit",
    meta: { authRequired: true },
    component: () =>
        import ("./views/bussiness_units/index"),
    props: true
},
{
    path: "/bussiness-unit-create/:social_reason_id/:name",
    name: "create-bussiness_unit",
    meta: { authRequired: true },
    component: () =>
        import ("./views/bussiness_units/create"),
    props: true
},
{
    path: "/bussiness-unit-edit/:id/:social_reason_id/:name",
    name: "edit-bussiness_unit",
    meta: { authRequired: true },
    component: () =>
        import ("./views/bussiness_units/edit"),
    props: true
},
{
    path: "/providers-index",
    name: "index-providers",
    meta: { authRequired: true },
    component: () =>
        import ("./views/providers/index"),
},
{
    path: "/providers",
    name: "providers",
    meta: { authRequired: true },
    component: () =>
        import ("./views/providers/create"),
},
{
    path: "/providers-edit/:id",
    name: "edit-providers",
    meta: { authRequired: true },
    component: () =>
        import ("./views/providers/edit"),
    props: true
},
{
    path: "/catalogs-index",
    name: "catalogs",
    meta: { authRequired: true },
    component: () =>
        import ("./views/catalogs/index"),
},
{
    path: "/catalogs-create",
    name: "create-catalogs",
    meta: { authRequired: true },
    component: () =>
        import ("./views/catalogs/create"),
},
{
    path: "/catalogs-management/:table_name",
    name: "management-catalogs",
    meta: { authRequired: true },
    component: () =>
        import ("./views/catalogs/managementIndex"),
    props: true
},
{
    path: "/catalogs-special/:table_name",
    name: "special-catalogs",
    meta: { authRequired: true },
    component: () =>
        import ("./views/catalogs/specialCatalog"),
    props: true
},
{
    path: "/catalogs-special-edit/:table_name/:id",
    name: "edit-special-catalogs",
    meta: { authRequired: true },
    component: () =>
        import ("./views/catalogs/specialCatalogEdit"),
    props: true
},
{
    path: "/cost-centers-index",
    name: "index-cost-centers",
    meta: { authRequired: true },
    component: () =>
        import ("./views/cost_centers/index"),
    props: true
},

{
    path: "/cost-centers-create",
    name: "create-cost-centers",
    meta: { authRequired: true },
    component: () =>
        import ("./views/cost_centers/create"),
    props: true
},
{
    path: "/cost-centers-edit/:id",
    name: "edit-cost-centers",
    meta: { authRequired: true },
    component: () =>
        import ("./views/cost_centers/edit"),
    props: true
},
{
    path: "/supplies-create",
    name: "create-supplies",
    meta: { authRequired: true },
    component: () =>
        import ("./views/supplies/create"),
    props: true
},
{
    path: "/supplies-index",
    name: "index-supplies",
    meta: { authRequired: true },
    component: () =>
        import ("./views/supplies/index"),
    props: true
},
{
    path: "/supplies-edit/:id",
    name: "edit-supplies",
    meta: { authRequired: true },
    component: () =>
        import ("./views/supplies/edit"),
    props: true
},
{
    path: "/construction_games-index",
    name: "index-construction_games",
    meta: { authRequired: true },
    component: () =>
        import ("./views/construction_games/create"),

},
{
    path: "/unit_price_cards-index/:budget_id?/:budget_name?",
    name: "index-unit_price_cards",
    meta: { authRequired: true },
    component: () =>
        import ("./views/unit_price_cards/index"),
    props: true
},
{
    path: "/unit_price_cards-management/:id/:budget_id?/:budget_name?",
    name: "management-unit_price_cards",
    meta: { authRequired: true },
    component: () =>
        import ("./views/unit_price_cards/management"),
    props: true,
    /*
      *FUNCION QUE REALIZA LA VALIDACION DE LOS PERMISOS Y SE APLICA 
        DIRECTAMENTE A LA RUTA PARA EVITAR QUE SE PUEDA ACCEDER A RECURSOS NO AUTORIZADOS
        */
    // beforeEnter: (to, from, next) => {
    //     const isLoggedIn = (store.state.session.permisions?.hasOwnProperty('P-2') &&
    //     store.state.session.permisions['P-2'][8] != undefined) || (store.state.session.nivel == 1); // Accede al estado de autenticación desde Vuex
    //     console.log('store.state.session',store.state.session)
    //     if (!isLoggedIn) {
    //       // Si el usuario no tiene permisos
    //       console.log('recurso no autorizado');
    //       return;
    //     } else {
          
    //       console.log(' autorizado');
    //       next();
    //       return;
    //     }
    //   }

},
{
    path: "/work_force-index",
    name: "index-work_force",
    meta: { authRequired: true },
    component: () =>
        import ("./views/workforce/index"),
    props: true

},
{
    path: "/fsr-index",
    name: "index-fsr",
    meta: { authRequired: true },
    component: () =>
        import ("./views/real_salary_factor/index"),
    props: true

},

{
    path: "/budget-index",
    name: "index-budget",
    meta: { authRequired: true },
    component: () =>
        import ("./views/budget_generate/index"),
    props: true

},
{
    path: "/budget-management/:id/:name/:is_admin",
    name: "management-budget",
    meta: { authRequired: true },
    component: () =>
        import ("./views/budget_generate/management"),
    props: true

},
{
    path: "/explosion-index",
    name: "explosion",
    meta: { authRequired: true },
    component: () =>
        import ("./views/explosion/index"),
    props: true
},
{
    path: "/explosion-management/:id/:name",
    name: "management-explosion",
    meta: { authRequired: true },
    component: () =>
        import ("./views/explosion/management"),
    props: true

},
{
    path: "/controlBudget-management/:id/:name",
    name: "management-controlBudget",
    meta: { authRequired: true },
    component: () =>
        import ("./views/control_budget/management"),
    props: true

},
{
    path: "/docuemnts",
    name: "management-documents",
    meta: { authRequired: true },
    component: () =>
        import ("./views/documents_management/index"),
    props: true

},

{
    path: "/requisitions",
    name: "requisitions-management",
    meta: { authRequired: true },
    component: () =>
        import ("./views/requisitions/index"),
    props: true

},

{
    path: "/requisitions/create/:id?",
    name: "requisitions-create",
    meta: { authRequired: true },
    component: () =>
        import ("./views/requisitions/new"),
    props: true

},
{
    path: "/requisitions/edit/:id?",
    name: "requisitions-edit",
    meta: { authRequired: true },
    component: () =>
        import ("./views/requisitions/new"),
    props: true

},
{
    path: "/assignments",
    name: "assignments-management",
    meta: { authRequired: true },
    component: () =>
        import ("./views/assignments/index"),
    props: true

},

{
    path: "/assignments/create/:id?",
    name: "assignments-create",
    meta: { authRequired: true },
    component: () =>
        import ("./views/assignments/new"),
    props: true

},
{
    path: "/proyects/create/:id?",
    name: "proyects-create",
    meta: { authRequired: true },
    component: () =>
        import ("./views/proyects/new"),
    props: true
    

},

{
    path: "/proyects",
    name: "proyects-index",
    meta: { authRequired: true },
    component: () =>
        import ("./views/proyects/index"),
    props: true
    

},







]



export default new VueRouter({
    routes, // short for `routes: routes`
});