<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->

  <div id="sidebar-menu">
    <div class="v-step-4"></div>

    <v-tour name="myTour" :options="myOptions" :steps="steps"></v-tour>
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="(item, index1) of menuItems">
        <li class="menu-title" v-if="item.isTitle" :key="index1">
          {{ $t(item.label) }}
        </li>

        <li v-if="!item.isTitle && !item.isLayout && item.id != 19" :key="item.id">
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              v-if="item.badge"
              >{{ $t(item.badge.text) }}</span
            >
          </a>

          <a :href="item.link" v-if="!hasItems(item)" >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              v-if="item.badge"
              >{{ $t(item.badge.text) }}</span
            >
          </a>

          <ul class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index2) of item.subItems" :key="index2">
              <template >
                <router-link
                  :to="subitem.link"
                  v-if="!hasItems(subitem) && permissionWrite(1, subitem.id)"
                  class="v-step-6 side-nav-link-ref"
                  >{{ $t(subitem.label) }}</router-link
                >
                <a
                  v-if="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                  >{{ item.id }}</a
                >
                <ul
                  v-if="hasItems(subitem)"
                  class="sub-menu mm-collapse"
                  aria-expanded="false"
                >
                  
                </ul>
              </template>
            
            </li>
          </ul>
        </li>

        <li v-if="!item.isTitle && !item.isLayout && item.id == 19" :key="item.id">
          
          <a :href="item.link" v-if="!hasItems(item)" >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              v-if="item.badge"
              >{{ $t(item.badge.text) }}</span
            >
          </a>

          
        </li>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState, mapActions, mapMutations } from "vuex";
import { menuItems, modificarMenu } from "./menu";

/**
 * Side-nav component
 */
export default {
  data() {
    return {
      sessionData: window.sessionData, // Asigna los datos de sesión a una propiedad de la instancia de Vue
      hasExecutedLogic: false,
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Omitir",
          buttonPrevious: "Anterior",
          buttonNext: "Siguiente",
          buttonStop: "Terminar",
        },
      },
      URL_HUMA: this.$root.env.URL_HUMA,
      menuItems: menuItems,
      steps: [
        {
          target: ".v-step-4", // We're using document.querySelector() under the hood
          header: {
            title: "Vamos a comenzar",
          },
          content: `Descubre la nueva experiencia de  <strong>HUMADESARROLLOS</strong>!`,
          params: {
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".v-step-5",
          content:
            "Aqui puedes crear o modificar tus tarjetas de precios unitarios",
          params: {
            enableScrolling: false,
            highlight: true,
            placement: "bottom",
          },
        },
        {
          target: ".v-step-6",
          content: "Aqui puedes crear generar o elaborar un presupueto base",
          params: {
            highlight: true,
            enableScrolling: false,
            placement: "bottom",
            // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
      ],
    };
  },
  created() {
    modificarMenu({
      id: 19,
      label: "HUMA V1",
      icon: "bx bx-left-arrow-alt",
      link: "/logout",
    });
  },
  computed: {
    ...mapState("Explosion", ["session"]),
  },
  mounted: function () {
    //this.$tours["myTour"].start();
    this.actiongetSession(this.sessionData);
    // console.log("sessionData", this.sessionData);
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("Explosion", ["actiongetSession"]),
    ...mapMutations("Explosion", ["getSession"]),
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    permissionWrite(id, permission) {
      let key = this.getKeyPermissions(permission);
      let id2 = this.getPermissionsId(permission);
      return (
        (sessionData.permisions.hasOwnProperty(key) &&
          sessionData.permisions[key][id2] != undefined) ||
        this.session.nivel == 1
      );
    },

     getPermissionsId(itemId){
      switch (itemId) {
        case 10:
          return 1;
        case 8:
          return 6;
        case 11:
          return 10;
        case 12:
          return 1;
        case 13:
          return 1;
        case 14:
          return 1;
        case 15:
          return 1;
        case 16:
          return 1;

        case 18:
          return 1;
        case 19:
          return null;
      }
    },
     getKeyPermissions(itemId) {
      switch (itemId) {
        case 10:
          return "P-2";
        case 8:
          return "P-2";
        case 11:
          return "P-2";
        case 12:
          return "R-4";
        case 13:
          return "R-5";
        case 14:
          return "R-6";
        case 15:
          return "R-7";
        case 16:
          return "R-8";

        case 18:
          return "R-3";
        case 19:
          return null;
      }
    },
  },
};
</script>
