/*
ESTE ES EL MODULO PRINCIPAL DEL STORE DADO QUE EN UN PRINCIPIO 
  SE NOMBRO DE ESTA MANERA SE DEJO ASI PARA EVITAR CONFLICTOS
  */
import Vue from 'vue'
import Vuex from 'vuex'
import Swal from "sweetalert2";
import sha1 from 'js-sha1';

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    infoBudget:'',
    explosionData: [],
    infoBaseConcepts:[],
    finalAmountCard:[],
    integrated:false,
    infoDocuments:[],
    session:[],
    budgetsBase:[],
    budgets: [],
    conceptsIdsToPay:[],

    swalFunction: (title, text, icon, confirmButtonText) => {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: title,
          text: text,
          icon: icon,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: confirmButtonText,
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(); // Resuelve la promesa si se confirma
          } else {
            reject(); // Rechaza la promesa si se cancela
          }
        });
      });
    },
    swalFunctionAuth: (title, confirmButtonText) => {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: title,
          input: "password",
          inputAttributes: {
            autocapitalize: "on",
            id: 'custom-input'
          },
          showCancelButton: true,
          confirmButtonText: confirmButtonText,
          showLoaderOnConfirm: true,
          onOpen: () => {
            // Desactiva el autocompletado después de que se abra el modal
            const inputElement = document.getElementById('custom-input');
            if (inputElement) {
              inputElement.setAttribute('autocomplete', 'new-password');
            }
          },
          preConfirm: async (login) => {
            try {
              
              const response = await fetch("api/authSignature/" +sha1(login));
              const data = await response.json();
              const resultado = data.result;
              console.log('response',resultado)
              if (!resultado) {
                
                return Swal.showValidationMessage(`
                  'CLAVE DE AUTORIZACION INCORRECTA'
                `);
              }
             
            
              return resultado;
            } catch (error) {
              Swal.showValidationMessage(`
                Request failed: ${error}
              `);
              reject();
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        },


        ).then((result) => {
        
          if (result.isConfirmed) {
            Swal.fire({
              title: `Aprobado con Exito`,
              
            });

            resolve();
          }else{
            console.log('result',result)
            reject();
          }
        });
      });
    },
  },
  getters: {
    canPermissions: (state, getters) => (id, permission) => {
      if (!state.session || !state.session.permisions) return false;
      
      let key = getters.getKeyPermissions(permission); 
      if (!key) return false; // Si no hay clave, retornar false
      
      return (
        (state.session.permisions.hasOwnProperty(key) &&
          state.session.permisions[key][id] !== undefined) ||
        state.session.nivel === 1
      );
    },
    

    getPermissionsId: () => (itemId) => {
      const mapping = {
        10: 1,
        8: 6,
        11: 10,
        12: 1,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        18: 1,
        19: null,
      };
      return mapping[itemId] || null;
    },

    getKeyPermissions: () => (itemId) => {
      const mapping = {
        10: "P-2",
        8: "P-2",
        11: "P-2",
        12: "R-4",
        13: "R-5",
        14: "R-6",
        15: "R-7",
        16: "R-8",
        18: "R-3",
        19: null,
      };
      return mapping[itemId] || null;
    },
  },
  mutations: {
    

    getConceptsIdsToPay(state, data) {
      state.conceptsIdsToPay = data;
    },
    getInfoBudget(state, data) {
      state.infoBudget = data;
    },
    getBudgets(state, data) {
      state.budgets = data;
    },
    getExplosionData(state, data) {
      state.explosionData = data;
    },
    getFinalAmountCard(state, data) {
      state.finalAmountCard = data;
    },
    getInfoDocuments(state, data) {
      state.infoDocuments = data;
    },
   
    getIntegrated(state, data) {
      state.integrated = data;
    },
    getSession(state, data) {
      state.session = data;
    },

    getbudgetsBase(state, data) {
      state.budgetsBase = data;
    },
    
  },
  actions: {
    actiongetInfoBudget({ commit }, val) {
      commit('getInfoBudget', val);
    },
    actiongetExplosionData({ commit }, val) {
      commit('getExplosionData', val);
    },
    actiongetFinalAmountCard({ commit }, val) {
      commit('getFinalAmountCard', val);
    },
    actiongetIntegrated({ commit }, val) {
      commit('getIntegrated', val);
    },
    actiongetInfoDocuments({ commit }, val) {
      commit('getInfoDocuments', val);
    },

    actiongetSession({ commit }, val) {
      commit('getSession', val);
    },
    actiongetbudgetsBase({ commit }, val) {
      commit('getbudgetsBase', val);
    },
    actiongetgetBudgets({ commit }, val) {
      commit('getBudgets', val);
    },

    actiongetConceptsIdsToPay({ commit }, val) {
      commit('getConceptsIdsToPay', val);
    },
    
    
  },
  


};








