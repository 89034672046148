import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    assignmentsInfo: {
      cost_center_id: [],
      description: '',
      contractor: [],
      resident: [],
      superintendent: [],
      concepts: [],
      root: [],
      apples: [],
      blocks:[]
    },
    rootsAvailable: [],
    suppliesItems: [] //PARA EL CATALOGO DE INSUMOS GENERL
  },
  mutations: {
    SET_ASG_INFO(state, value) {
      state.assignmentsInfo = value;
    },
    RESET_ASG_INFO(state) {
      state.assignmentsInfo = {
        cost_center_id: [],
        description: '',
        contractor: [],
        resident: [],
        superintendent: [],
        concepts: [],
        root: [],
        apples: [],
        blocks:[]


      };
    },
    setBlockData(state, data ){


      state.assignmentsInfo = {
        ...state.assignmentsInfo,
        blocks: data
      };

    },
    getInfoAssignment(state, data) {
      state.assignmentsInfo = data;
    },
    SET_ROOTS_AVAILABLE(state, value) {
      state.rootsAvailable = value;
    },
    RESET_STATE(state) {
      // Reestablece todo el estado del módulo
      Object.assign(state, {
        assignmentsInfo: {
          cost_center_id: [],
          description: '',
          contractor: [],
          resident: [],
          superintendent: [],
          concepts: [],
          root: [],
          apples: [],
          blocks:[]

        },
        rootsAvailable: [],
        suppliesItems: []
      });
    }
  },
  actions: {
    actionSettAsgData({
      commit
    }, val) {
      commit('SET_ASG_INFO', val);
    },
    actionSetBlockData({
      commit
    }, val) {
      commit('setBlockData', val);
    },
    actiongetInfoAssignment({
      commit
    }, val) {
      commit('getInfoAssignment', val);
    },
    actionSettRootAvailable({
      commit
    }, val) {
      commit('SET_ROOTS_AVAILABLE', val);
    },
    resetAssignmentsInfo({
      commit
    }) {
      commit('RESET_ASG_INFO');
    },

    resetState({ commit }) {
      commit('RESET_STATE'); // Llama a la mutación para reiniciar todo el estado
    }
  },



};