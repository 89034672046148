import { array } from 'js-sha1';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    requisitionsInfo:{
      req_huma_id:null,

      id:null, 
        cost_center_id:[],
        description:'',
        date:'',
        status:1,
        dataSupplies:[],
        root:[],
        mode:1
       
       
    
       
    },
    rootsAvailable: [],
    suppliesItems:[]//PARA EL CATALOGO DE INSUMOS GENERL
  },
  mutations: {
    // setRootsAvailable(state, payload) {
    //     state.rootsAvailable = payload;
    //   }
    RESET_REQ_INFO(state) {
      state.requisitionsInfo = {
        id: null, 
        cost_center_id: [],
        description: '',
        date: '',
        status: 1,
        dataSupplies: [],
        root: [],
        mode:1
      };
    },
    SET_REQ_INFO(state, value) {
      state.requisitionsInfo = value;
    },
    SET_SUPPLIES(state, value) {
      state.suppliesItems = value;
    },
    SET_ROOTS_AVAILABLE(state, value) {
      state.rootsAvailable = value;
    },
    
  },
  actions: {
    resetRequisitionsInfo({ commit }) {
      commit('RESET_REQ_INFO');
    },
    actionSettRootAvailable({ commit }, val) {
      commit('SET_ROOTS_AVAILABLE', val);
    },

    actionSettReqData({ commit }, val) {
      commit('SET_REQ_INFO', val);
    },
    actionSeTSupplies({ commit }, val) {
      commit('SET_SUPPLIES', val);
    },
  },
  
  getters: {
    addSupplieToReq(state) {

    },
    getAssignmentDescription(state) {
      return state.assignmentsInfo.description;
    },
    getContractorInfo(state) {
      return state.assignmentsInfo.contractor;
    }
  }

};








