import {
    array
} from 'js-sha1';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        aditivesInfo: {
            req_huma_id: null,
            id: null,
            cost_center_id: [],
            description: '',
            date: '',
            status: 1,
            dataSupplies: [],
            root: [],
            mode: 1




        },
        rootsAvailable: [],
        suppliesItems: [] //PARA EL CATALOGO DE INSUMOS GENERL
    },
    mutations: {
        // setRootsAvailable(state, payload) {
        //     state.rootsAvailable = payload;
        //   }
        RESET_AD_INFO(state) {
            state.aditivesInfo = {
                id: null,
                cost_center_id: [],
                description: '',
                date: '',
                status: 1,
                dataSupplies: [],
                root: [],
                mode: 1
            };
        },
        SET_AD_INFO(state, value) {
            state.aditivesInfo = value;
        },
        SET_SUPPLIES(state, value) {
            state.suppliesItems = value;
        },
        SET_ROOTS_AVAILABLE(state, value) {
            state.rootsAvailable = value;
        },

    },
    actions: {
        resetaditivesInfo({
            commit
        }) {
            commit('RESET_AD_INFO');
        },
        actionSettRootAvailable({
            commit
        }, val) {
            console.log('val', val)
            commit('SET_ROOTS_AVAILABLE', val);
        },

        actionSettAdData({
            commit
        }, val) {
            console.log('val', val)
            commit('SET_AD_INFO', val);
        },
        actionSeTSupplies({
            commit
        }, val) {
            console.log('val', val)
            commit('SET_SUPPLIES', val);
        },
    },

    getters: {
        addSupplieToReq(state) {

        },
        getAssignmentDescription(state) {
            return state.assignmentsInfo.description;
        },
        getContractorInfo(state) {
            return state.assignmentsInfo.contractor;
        }
    }

};