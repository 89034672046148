import Vue from 'vue'
import Vuex from 'vuex'
import Explosion from './explosion'
import Assignments from './assignments'
import Requisitions from './requisitions'
import Proyects from './proyects'
import Aditives from './aditives'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Explosion,
    Assignments,
    Requisitions,
    Proyects,
    Aditives
  }
})
