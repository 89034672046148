import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    assignmentsInfo:{
        cost_center_id:[],
        description:'',
        contractor:[],
        resident:[],
        superintendent:[],
        concepts:[],
        root:[],
        apples:[]
    },
    rootsAvailable: [],
    suppliesItems:[]//PARA EL CATALOGO DE INSUMOS GENERL
  },
  mutations: {
    SET_ASG_INFO(state, value) {
      state.assignmentsInfo = value;
    },
    RESET_ASG_INFO(state) {
      state.assignmentsInfo = {
        cost_center_id:[],
        description:'',
        contractor:[],
        resident:[],
        superintendent:[],
        concepts:[],
        root:[],
        apples:[]

      };
    },
    getInfoAssignment(state, data) {
        state.assignmentsInfo = data;
      },
      SET_ROOTS_AVAILABLE(state, value) {
        state.rootsAvailable = value;
      },
  },
  actions: {
    actionSettAsgData({ commit }, val) {
      console.log('val',val)
      commit('SET_ASG_INFO', val);
    },
    actiongetInfoAssignment({ commit }, val) {
        console.log('val',val)
        commit('getInfoAssignment', val);
      }, 
      actionSettRootAvailable({ commit }, val) {
        console.log('val',val)
        commit('SET_ROOTS_AVAILABLE', val);
      },   
      resetAssignmentsInfo({ commit }) {
        commit('RESET_ASG_INFO');
      },
  },
  


};








