import Vue from 'vue'
import Vuex from 'vuex'
import Swal from "sweetalert2";
import sha1 from 'js-sha1';

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    infoBudget:'',
    explosionData: [],
    infoBaseConcepts:[],
    finalAmountCard:[],
    integrated:false,
    infoDocuments:[],
    session:[],
    budgetsBase:[],
    budgets: [],
    conceptsIdsToPay:[],

    swalFunction: (title, text, icon, confirmButtonText) => {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: title,
          text: text,
          icon: icon,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: confirmButtonText,
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(); // Resuelve la promesa si se confirma
          } else {
            reject(); // Rechaza la promesa si se cancela
          }
        });
      });
    },
    swalFunctionAuth: (title, confirmButtonText) => {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: title,
          input: "password",
          inputAttributes: {
            autocapitalize: "on",
            id: 'custom-input'
          },
          showCancelButton: true,
          confirmButtonText: confirmButtonText,
          showLoaderOnConfirm: true,
          onOpen: () => {
            // Desactiva el autocompletado después de que se abra el modal
            const inputElement = document.getElementById('custom-input');
            if (inputElement) {
              inputElement.setAttribute('autocomplete', 'new-password');
            }
          },
          preConfirm: async (login) => {
            try {
              
              const response = await fetch("api/authSignature/" +sha1(login));
              const data = await response.json();
              const resultado = data.result;
              console.log('response',resultado)
              if (!resultado) {
                
                return Swal.showValidationMessage(`
                  'CLAVE DE AUTORIZACION INCORRECTA'
                `);
              }
             
            
              return resultado;
            } catch (error) {
              Swal.showValidationMessage(`
                Request failed: ${error}
              `);
              reject();
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        },


        ).then((result) => {
        
          if (result.isConfirmed) {
            Swal.fire({
              title: `Aprobado con Exito`,
              
            });

            resolve();
          }else{
            console.log('result',result)
            reject();
          }
        });
      });
    },
  },
  mutations: {
    

    getConceptsIdsToPay(state, data) {
      state.conceptsIdsToPay = data;
    },
    getInfoBudget(state, data) {
      state.infoBudget = data;
    },
    getBudgets(state, data) {
      state.budgets = data;
    },
    getExplosionData(state, data) {
      state.explosionData = data;
    },
    getFinalAmountCard(state, data) {
      state.finalAmountCard = data;
    },
    getInfoDocuments(state, data) {
      state.infoDocuments = data;
    },
   
    getIntegrated(state, data) {
      state.integrated = data;
    },
    getSession(state, data) {
      state.session = data;
    },

    getbudgetsBase(state, data) {
      state.budgetsBase = data;
    },
    
  },
  actions: {
    actiongetInfoBudget({ commit }, val) {
      console.log('val',val)
      commit('getInfoBudget', val);
    },
    actiongetExplosionData({ commit }, val) {
      commit('getExplosionData', val);
    },
    actiongetFinalAmountCard({ commit }, val) {
      commit('getFinalAmountCard', val);
    },
    actiongetIntegrated({ commit }, val) {
      commit('getIntegrated', val);
    },
    actiongetInfoDocuments({ commit }, val) {
      commit('getInfoDocuments', val);
    },

    actiongetSession({ commit }, val) {
      commit('getSession', val);
    },
    actiongetbudgetsBase({ commit }, val) {
      commit('getbudgetsBase', val);
    },
    actiongetgetBudgets({ commit }, val) {
      commit('getBudgets', val);
    },

    actiongetConceptsIdsToPay({ commit }, val) {
      commit('getConceptsIdsToPay', val);
    },
    
    
  },
  


};








